
import { defineComponent } from "vue";

export default defineComponent({
  name: "kt-widget-12",
  components: {},
  props: {
    widgetClasses: String,
  },
  data(): {
    DateType: string;
    startDate: string;
    list: any;
  } {
    return {
      DateType: "",
      startDate: "",
      list: [
        {
          Enable: 0,
          Edit: 0,
          Type1: "新增/修改/查詢權限",
          Type2: "只限查詢權限",
          Type3: "只限查詢權限",
          Type4: "只限查詢權限",
          client: {
            Name: "A公司",
          },
          order: {
            Number: "業務部",
            Itemnumber: "100",
            Date: "100",
          },
          shipping: {
            Date: "201223456",
          },
        },
        {
          Enable: 1,
          Edit: 0,
          Type1: "新增/修改/查詢權限",
          Type2: "只限查詢權限",
          Type3: "只限查詢權限",
          Type4: "只限查詢權限",
          client: {
            Name: "B公司",
          },
          order: {
            Number: "業務部",
            Itemnumber: "101",
            Date: "102",
          },
          shipping: {
            Date: "101223456",
          },
        },
        {
          Enable: 1,
          Edit: 0,
          Type1: "新增/修改/查詢權限",
          Type2: "只限查詢權限",
          Type3: "只限查詢權限",
          Type4: "只限查詢權限",
          client: {
            Name: "C公司",
          },
          order: {
            Number: "業務部",
            Itemnumber: "102",
            Date: "99",
          },
          shipping: {
            Date: "301223456",
          },
        },
      ],
    };
  },
  methods: {
    Edit(evt) {
      let index = evt.target.dataset.index;
      if (this.list[index].Edit == 0) {
        this.list[index].Edit = 1;
      } else {
        this.list[index].Edit = 0;
      }
    },
    Enable(evt) {
      let index = evt.target.dataset.index;
      if (this.list[index].Enable == 0) {
        this.list[index].Enable = 1;
      } else {
        this.list[index].Enable = 0;
      }
    },
  },
});
