import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-wrap flex-stack my-5" }
const _hoisted_2 = { class: "col-12 d-flex flex-wrap my-1" }
const _hoisted_3 = { class: "m-0 col-12 p-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ClientList = _resolveComponent("ClientList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_ClientList)
      ])
    ])
  ]))
}