
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ClientList from "@/ComponentsSystem/Account/ClientList.vue";

export default defineComponent({
  name: "projects",
  components: {
    ClientList,
  },
  data(): {
    DateType: string;
    startDate: string;
    endDate: string;
  } {
    return {
      DateType: "",
      startDate: "",
      endDate: "",
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("客戶權限管理", ["戰情中台", "帳號管理"]);
    });
  },
});
